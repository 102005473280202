import React, { useState, useEffect } from 'react';

const App = () => {
  const [form, setForm] = useState({ username: '', reason: '' });
  const [warnings, setWarnings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginForm, setLoginForm] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [userForm, setUserForm] = useState({ username: '', password: '', role: 'user' });
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMod, setIsMod] = useState(false);
  const [activeTab, setActiveTab] = useState('warnings');
  const [newPassword, setNewPassword] = useState('');

  // Function to handle user authentication
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch('https://mod.api.dfined.net/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginForm),
      });

      const contentType = res.headers.get('content-type');
      let responseData;

      // Check if the response is JSON
      if (contentType && contentType.includes('application/json')) {
        responseData = await res.json();
      } else {
        responseData = await res.text();
      }

      if (res.ok) {
        localStorage.setItem('token', responseData.token);
        setIsAuthenticated(true);
        setIsAdmin(responseData.role === 'admin');
        setIsMod(responseData.role === 'mod');
        setError('');
      } else {
        setError(typeof responseData === 'string' ? responseData : 'Invalid credentials');
      }
    } catch (err) {
      setError('Error logging in');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      const payload = JSON.parse(atob(token.split('.')[1]));
      setIsAdmin(payload.role === 'admin');
      setIsMod(payload.role === 'mod');
    }

    const fetchWarnings = async () => {
      try {
        const res = await fetch('https://mod.api.dfined.net/warnings');
        const data = await res.json();
        setWarnings(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error('Error fetching warnings:', err);
        setWarnings([]);
      }
    };

    fetchWarnings();
  }, [isAuthenticated]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const res = await fetch('https://mod.api.dfined.net/warnings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });
      const data = await res.json();
      setWarnings([...warnings, data]);
      setForm({ username: '', reason: '' });
    } catch (err) {
      console.error('Error submitting warning:', err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await fetch(`https://mod.api.dfined.net/warnings/${id}`, {
        method: 'DELETE',
      });
      setWarnings(warnings.filter((warning) => warning.id !== id));
    } catch (err) {
      console.error('Error deleting warning:', err);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleLoginChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const handleUserChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const res = await fetch('https://mod.api.dfined.net/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userForm),
      });
      const data = await res.json();
      console.log('User created:', data);
      setUserForm({ username: '', password: '', role: 'user' });
    } catch (err) {
      console.error('Error creating user:', err);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredWarnings = warnings.filter((warning) =>
    warning.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const res = await fetch('https://mod.api.dfined.net/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ newPassword }),
      });

      if (res.ok) {
        setNewPassword('');
        alert('Password changed successfully!');
      } else {
        const errorData = await res.json();
        alert(`Error changing password: ${errorData.message}`);
      }
    } catch (err) {
      console.error('Error changing password:', err);
    }
  };

  // Function to check if the user is a regular user
  const isUser = () => !isAdmin && !isMod;

  return (
    <div className="relative h-screen">
      <div
        className="absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center"
        style={{
          backgroundImage: `url('https://cdn.neowin.com/news/images/galleries/4686/1654793763_2-ventura-bloom-dark.jpg')`,
          filter: 'blur(8px)',
          zIndex: -1,
        }}
      ></div>

      {!isAuthenticated ? (
        <div className="h-full flex items-center justify-center">
          <div className="w-full max-w-md p-8 bg-gray-800/70 rounded-lg backdrop-blur-lg shadow-lg">
            <h1 className="text-3xl font-semibold text-white mb-6">Login</h1>

            <form onSubmit={handleLogin} className="mb-6">
              <div className="mb-4">
                <label
                  htmlFor="loginUsername"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Username
                </label>
                <input
                  id="loginUsername"
                  name="username"
                  type="text"
                  value={loginForm.username}
                  onChange={handleLoginChange}
                  className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="loginPassword"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Password
                </label>
                <input
                  id="loginPassword"
                  name="password"
                  type="password"
                  value={loginForm.password}
                  onChange={handleLoginChange}
                  className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white"
                  required
                />
              </div>

              {error && <div className="text-red-500 mb-4">{error}</div>}

              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-md hover:bg-blue-500 transition duration-150 ease-in-out"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="h-full flex items-center justify-center">
          <div className="w-full max-w-lg p-8 bg-gray-800/70 rounded-lg backdrop-blur-lg shadow-lg">
            <h1 className="text-3xl font-semibold text-white mb-6">⚠️ Warning Tracker</h1>

            {/* Tab Navigation */}
            <div className="mb-4">
              <button
                onClick={() => setActiveTab('warnings')}
                className={`py-2 px-4 rounded-l-lg ${activeTab === 'warnings' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
              >
                Warnings
              </button>
              {isAdmin && (
                <button
                  onClick={() => setActiveTab('user-management')}
                  className={`py-2 px-4 ${activeTab === 'user-management' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                >
                  User Management
                </button>
              )}
              <button
                onClick={() => setActiveTab('settings')}
                className={`py-2 px-4 rounded-r-lg ${activeTab === 'settings' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
              >
                Settings
              </button>
            </div>

            {/* Tab Content */}
            {activeTab === 'warnings' && (
              <div>

                <form onSubmit={handleSubmit} className="mb-6">
                  <div className="mb-4">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-gray-300 mb-2"
                    >
                      Username
                    </label>
                    <input
                      id="username"
                      name="username"
                      type="text"
                      value={form.username}
                      onChange={handleChange}
                      className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="reason"
                      className="block text-sm font-medium text-gray-300 mb-2"
                    >
                      Reason
                    </label>
                    <input
                      id="reason"
                      name="reason"
                      type="text"
                      value={form.reason}
                      onChange={handleChange}
                      className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-md hover:bg-blue-500 transition duration-150 ease-in-out"
                  >
                    Add Warning
                  </button>
                </form>

                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Search by username..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white"
                  />
                </div>

                <div className="max-h-80 overflow-y-scroll">
                  <h2 className="text-lg font-semibold text-white mb-2">Warnings List</h2>
                  {filteredWarnings.length > 0 ? (
                    filteredWarnings.map((warning) => (
                      <div key={warning.id} className="flex justify-between items-center bg-gray-700/80 p-3 rounded-lg mb-2">
                        <span className="text-gray-300">{warning.username}: {warning.reason}</span>
                        {isAdmin || isMod ? (
                          <button
                            onClick={() => handleDelete(warning.id)}
                            className="bg-red-600 text-white py-1 px-3 rounded-lg hover:bg-red-500 transition duration-150 ease-in-out"
                          >
                            Delete
                          </button>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <div className="text-gray-400">No warnings found.</div>
                  )}
                </div>
              </div>
            )}

            {/* User Management Tab */}
            {activeTab === 'user-management' && isAdmin && (
              <div>
                <h2 className="text-lg font-semibold text-white mb-2">User Management</h2>
                <form onSubmit={handleUserSubmit} className="mb-4">
                  <div className="flex mb-4">
                    <div className="flex-1 mr-2">
                      <input
                        type="text"
                        name="username"
                        value={userForm.username}
                        onChange={handleUserChange}
                        placeholder="Username"
                        className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white"
                        required
                      />
                    </div>
                    <div className="flex-1 ml-2">
                      <input
                        type="password"
                        name="password"
                        value={userForm.password}
                        onChange={handleUserChange}
                        placeholder="Password"
                        className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white"
                        required
                      />
                    </div>
                  </div>
                  <select
                    name="role"
                    value={userForm.role}
                    onChange={handleUserChange}
                    className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white mb-4"
                  >
                    <option value="user">User</option>
                    <option value="mod">Moderator</option>
                    <option value="admin">Admin</option>
                  </select>
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-md hover:bg-blue-500 transition duration-150 ease-in-out"
                  >
                    Create User
                  </button>
                </form>
              </div>
            )}

            {/* Settings Tab */}
            {activeTab === 'settings' && (
              <div>
                <h2 className="text-lg font-semibold text-white mb-2">Change Password</h2>
                <form onSubmit={handleChangePassword} className="mb-4">
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700/80 backdrop-blur-sm shadow-inner text-white mb-4"
                    required
                  />
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-md hover:bg-blue-500 transition duration-150 ease-in-out"
                  >
                    Change Password
                  </button>
                </form>
              </div>
            )}

            <button
              onClick={handleLogout}
              className="mt-6 w-full bg-red-600 text-white py-3 rounded-lg shadow-md hover:bg-red-500 transition duration-150 ease-in-out"
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
